
// Login.js
import React from "react";

const Login = ({ handleLogin }) => {
  return (
    <div className="bg-white text-black min-h-screen">
      <div className="flex w-full h-screen">
        <div className="w-full flex items-center justify-center lg:w-1/2">
          <div className="bg-white px-10 py-10 rounded-3xl border-2 border-gray-200 max-w-md mx-auto mt-16 shadow-lg">
            <h2 className="text-2xl font-semibold text-center">Welcome To</h2>
            <h1 className="text-5xl font-bold text-[#34e89e] text-center mb-6">
              DaySync
            </h1>

            <p className="text-gray-600 mt-6">
              To make the most out of DaySync, we will need access to your
              Google Calendar events. By granting us this permission, you enable
              DaySync to automatically suggest time slots that are free, helping
              you visually plan your day more effectively. Our goal is to
              maximize your productivity and ensure that you use your available
              time slots for activities that matter to you.
            </p>

            <div className="flex justify-center mt-8">
              <button
                onClick={handleLogin}
                className="flex items-center justify-center gap-2 text-white bg-gradient-to-r from-[#0f3443] to-[#34e89e] px-5 py-3 rounded-lg hover:from-[#34e89e] hover:to-[#0f3443] transition-all duration-300"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/google-logo.png"
                  alt="Google Logo"
                  className="w-6 h-6"
                />
                Login with Google
              </button>
            </div>
          </div>
        </div>
        <div className="hidden relative lg:flex h-full w-1/2 items-center justify-center bg-gray-200">
          <div className="w-60 h-60 bg-gradient-to-tr  to-[#0f3443] from-[#34e89e] rounded-full animate-bounce"></div>
          <div className="w-full h-1/2 absolute bottom-0 bg-white/10 backdrop-blur-lg"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
