import { useJsApiLoader } from "@react-google-maps/api";
import { useState, useCallback, useEffect, useRef } from "react";
import LLMText from "./LLMText";

function TextAPISearch({ city, date }) {
  // const [latitude, setLatitude] = useState(0.0);
  // const [longitude, setLongitude] = useState(0.0);
  const [restaurants, setRestaurants] = useState([]);
  const [landMarks, setLandMarks] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const serviceRef = useRef(null);

  // Helper function
  function formatArray(array, setFunction) {
    const formattedArray = array.map((place) => ({
      place_id: place.place_id,
      name: place.name,
      address: place.formatted_address,
    }));
    setFunction(formattedArray);
  }

  // Load API
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  // Initialize PlacesService
  useEffect(() => {
    if (isLoaded && window.google && !serviceRef.current) {
      serviceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [isLoaded]);

  // Fetch restaurants
  const fetchRestaurants = useCallback(() => {
    if (serviceRef.current) {
      const query = `Restaurants in ${city}`;
      const request = {
        query,
        fields: [
          "name",
          "geometry",
          "rating",
          "vicinity",
          "place_id",
          "opening_hours",
          "website",
        ],
      };

      serviceRef.current.textSearch(request, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          formatArray(results.slice(0, 15), setRestaurants);
        } else {
          setRestaurants([]);
          console.error("No restaurants found or error occurred:", status);
        }
      });
    }
  }, [city]);

  // Fetch landmarks
  const fetchLandMarks = useCallback(() => {
    if (serviceRef.current) {
      const query = `Land Marks in ${city}`;
      const request = {
        query,
        fields: [
          "name",
          "geometry",
          "rating",
          "vicinity",
          "place_id",
          "opening_hours",
          "website",
        ],
      };

      serviceRef.current.textSearch(request, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          formatArray(results.slice(0, 15), setLandMarks);
        } else {
          setLandMarks([]);
          console.error("No landmarks found or error occurred:", status);
        }
      });
    }
  }, [city]);

  // Fetch data when isLoaded changes or city changes
  useEffect(() => {
    if (isLoaded) {
      fetchRestaurants();
      fetchLandMarks();
    }
  }, [isLoaded, fetchRestaurants, fetchLandMarks]);

  // Update finalArray when restaurants and landMarks update
  useEffect(() => {
    if (restaurants.length && landMarks.length) {
      setFinalArray([...restaurants, ...landMarks]);
      console.log("Final array is: ", [...restaurants, ...landMarks]);
    }
  }, [restaurants, landMarks]);

  return (
    <div>
      {isLoaded ? <LLMText places={finalArray} date = {date}/> : <h2>Loading results...</h2>}
    </div>
  );
}

export default TextAPISearch;
