import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import About from "./pages/About";
import Home from "./pages/Home";
// import Calendar from "./pages/Calendar";
// import Contact from "./components/LLMText";
// import Login from "./pages/login/Login";
// import Signup from "./pages/signup/Signup";
import Auth from "./pages/OAuth"; // Import Auth component

//Adding comment
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Handle login (set isAuthenticated to true)
  const handleAuthChange = (authenticated) => {
    setIsAuthenticated(authenticated);
  };

  // Handle logout logic
  const handleLogout = async () => {
    try {
      const response = await fetch(
        "https://hidden-field-eb43.vescu2.workers.dev/auth/logout",
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setIsAuthenticated(false); // Update state to reflect logout
      } else {
        console.error("Failed to log out.");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Check authentication status on initial mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(
          "https://hidden-field-eb43.vescu2.workers.dev/auth/isAuthenticated",
          {
            credentials: "include",
          }
        );
        const data = await response.json();
        setIsAuthenticated(data.authenticated);
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };
    checkAuthStatus();
  }, []);

  return (
    <BrowserRouter>
      <header className="bg-gradient-to-r from-[#0f3443] to-[#34e89e] p-4 shadow-lg">
        <nav className="flex items-center justify-center max-w-6xl mx-auto">
          <NavLink to="/" className="flex-grow">
            <h1 className="text-5xl font-bold text-white text-center">
              DaySync
            </h1>
          </NavLink>
          <div className="flex gap-6 items-center absolute right-8">
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
                  : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
              }
            >
              About Us
            </NavLink>
            {isAuthenticated && (
              <button
                onClick={handleLogout}
                className="text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
              >
                Logout
              </button>
            )}
          </div>
        </nav>
      </header>

      <main>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/login"
            element={<Auth onAuthChange={handleAuthChange} />}
          />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;

// // import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
// // import About from "./pages/About";
// // import Home from "./pages/Home";
// // import Calendar from "./pages/Calendar";
// // import Contact from "./pages/Contact";
// // import Login from "./pages/login/Login";
// // import Signup from "./pages/signup/Signup";

// // function App() {
// //   return (
// //     <BrowserRouter>
// //       <header className="bg-gradient-to-r  from-[#0f3443] to-[#34e89e] p-4 shadow-lg">
// //         <nav className="flex items-center justify-between max-w-6xl mx-auto">
// //           <h1 className="text-2xl font-bold text-white border-b-4 border-[#34e89e]">
// //             Calendar App
// //           </h1>
// //           <div className="flex gap-6">
// //             <NavLink
// //               to="/"
// //               className={({ isActive }) =>
// //                 isActive
// //                   ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
// //                   : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
// //               }
// //             >
// //               Home
// //             </NavLink>
// //             <NavLink
// //               to="/calendar"
// //               className={({ isActive }) =>
// //                 isActive
// //                   ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
// //                   : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
// //               }
// //             >
// //               Calendar
// //             </NavLink>
// //             <NavLink
// //               to="/about"
// //               className={({ isActive }) =>
// //                 isActive
// //                   ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
// //                   : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
// //               }
// //             >
// //               About Us
// //             </NavLink>
// //             <NavLink
// //               to="/contact"
// //               className={({ isActive }) =>
// //                 isActive
// //                   ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
// //                   : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
// //               }
// //             >
// //               Contact Us
// //             </NavLink>
// //             <NavLink
// //               to="/login"
// //               className={({ isActive }) =>
// //                 isActive
// //                   ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
// //                   : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
// //               }
// //             >
// //               Login
// //             </NavLink>
// //           </div>
// //         </nav>
// //       </header>

// //       <main>
// //         <Routes>
// //           <Route index element={<Home />} />
// //           <Route path="/calendar" element={<Calendar />} />
// //           <Route path="/about" element={<About />} />
// //           <Route path="/contact" element={<Contact />} />
// //           <Route path="/login" element={<Login />} />
// //           <Route path="/signup" element={<Signup />} />
// //         </Routes>
// //       </main>
// //     </BrowserRouter>
// //   );
// // }

// // export default App;

// import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
// import About from "./pages/About";
// import Home from "./pages/Home";
// import Calendar from "./pages/Calendar";
// import Contact from "./pages/Contact";
// import Login from "./pages/login/Login";
// import Signup from "./pages/signup/Signup";

// function App() {
//   return (
//     <BrowserRouter>
//       <header className="bg-gradient-to-r from-[#0f3443] to-[#34e89e] p-4 shadow-lg">
//         <nav className="flex items-center justify-center max-w-6xl mx-auto">
//           <NavLink to="/" className="flex-grow">
//             <h1 className="text-5xl font-bold text-white text-center">
//               DaySync
//             </h1>
//           </NavLink>
//           <div className="flex gap-6 items-center absolute right-8">
//             <NavLink
//               to="/about"
//               className={({ isActive }) =>
//                 isActive
//                   ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
//                   : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
//               }
//             >
//               About Us
//             </NavLink>
//             {/* <NavLink
//               to="/login"
//               className={({ isActive }) =>
//                 isActive
//                   ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
//                   : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
//               }
//             >
//               Login
//             </NavLink> */}
//           </div>
//         </nav>
//       </header>

//       <main>
//         <Routes>
//           <Route index element={<Home />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//         </Routes>
//       </main>
//     </BrowserRouter>
//   );
// }

// export default App;
