import React from "react";
import { NavLink } from "react-router-dom"; // For footer links

function About() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      {/* About Us Section */}
      <section className="max-w-6xl mx-auto p-6">
        <h2 className="text-4xl font-bold mb-6 text-[#0f3443]">About Us</h2>

        {/* Introduction */}
        <div className="mb-8">
          <p className="text-lg leading-7">
            Welcome to our innovative app, designed to help you make the most of
            your free time. Whether it's a break between meetings or after work,
            our platform lets you visually plan your day, fitting activities
            into your available time slots.
          </p>
        </div>

        {/* Key Features */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-[#34e89e]">
            Key Features
          </h3>
          <ul className="list-disc ml-6 mt-4 space-y-2 text-lg">
            <li>
              Visualize your day with a planner that fits activities into your
              free time slots.
            </li>
            <li>
              Discover nearby places and activities based on your location and
              schedule.
            </li>
            <li>
              Save previous plans for future reference or recreating your ideal
              day.
            </li>
            <li>
              Integration with Google Calendar to automatically suggest free
              time slots.
            </li>
            <li>
              AI-powered suggestions based on your preferences and schedule.
            </li>
            <li>
              Google Maps integration to check travel time and availability of
              locations.
            </li>
          </ul>
        </div>

        {/* Team Members */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-[#34e89e]">
            Meet the Team
          </h3>
          <p className="text-lg leading-7 mt-4">
            Our team is dedicated to helping you make the most of your free
            time. Here's who made it possible:
          </p>
          <ul className="list-none mt-4 space-y-2 text-lg">
            <li>
              <strong>Victor Escudero</strong>
            </li>
            <li>
              <strong>Jesus Ramirez</strong>
            </li>
            <li>
              <strong>Zaheer Safi</strong>
            </li>
          </ul>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-[#0f3443] to-[#34e89e] p-8 text-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <h5 className="text-lg font-bold">Quick Links</h5>
            <ul className="mt-4">
              <li>
                <NavLink to="/" className="hover:underline">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/discover" className="hover:underline">
                  Discover
                </NavLink>
              </li>
              <li>
                <NavLink to="/planner" className="hover:underline">
                  Planner
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" className="hover:underline">
                  About
                </NavLink>
              </li>
            </ul>
          </div>
          <div>
            <h5 className="text-lg font-bold">Get Involved</h5>
            <ul className="mt-4">
              <li>
                <NavLink to="/register" className="hover:underline">
                  Sign Up
                </NavLink>
              </li>
              <li>
                <NavLink to="/login" className="hover:underline">
                  Login
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="text-center md:text-right">
            <h5 className="text-lg font-bold">Our App</h5>
            <p className="mt-4">
              Helping you make the most of your time, every day.
            </p>
            <p className="mt-2">
              &copy; {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default About;
