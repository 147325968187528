// UserInput.js
import React, { useState } from "react";
import TextAPISearch from "../components/TextAPISearch";

function UserInput() {
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false); // New state to control calendar visibility

  // const places = [
  //   {
  //     place_id: "ChIJc402QLwsDogRVvYEUI4EOF4",
  //     name: "The Rookery Building",
  //     address: "209 S La Salle St, Chicago, IL 60604",
  //   },
  // ];

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleStartPlanning = () => {
    console.log("Start planning for", city, "on", date);
    setShowCalendar(true); // Set to true to show the calendar after planning starts
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      {!showCalendar ? (
        <div className="bg-white p-8 rounded-lg shadow-lg w-80 text-center">
          <h1 className="text-2xl font-semibold mb-6 text-gray-800">
            Plan Your Visit
          </h1>
          <div className="mb-4">
            <label className="block text-gray-600 mb-1" htmlFor="city">
              City name
            </label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={handleCityChange}
              placeholder="Enter city name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-600 mb-1" htmlFor="date">
              Date
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={handleDateChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <button
            onClick={handleStartPlanning}
            className="w-full bg-blue-600 text-white py-2 rounded-md font-semibold hover:bg-blue-700 transition-colors"
          >
            Start Planning
          </button>
        </div>
      ) : (
        // <Calendar places={places} />
        <TextAPISearch city={city} date={date} />
      )}
    </div>
  );
}

export default UserInput;
