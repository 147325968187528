// // Auth.js
// import React, { useEffect, useState } from "react";

// import Login from "./login/Login";

// function Auth({ onAuthChange }) {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogout = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/auth/logout", {
//         method: "POST",
//         credentials: "include",
//       });
//       if (response.ok) {
//         setIsAuthenticated(false);
//         onAuthChange(false); // Notify parent about the change
//       } else {
//         console.error("Failed to log out.");
//       }
//     } catch (error) {
//       console.error("Error logging out:", error);
//     }
//   };

//   // Check authentication status on mount
//   useEffect(() => {
//     const checkAuthStatus = async () => {
//       try {
//         const response = await fetch(
//           "http://localhost:8000/auth/isAuthenticated",
//           {
//             credentials: "include",
//           }
//         );
//         const data = await response.json();
//         setIsAuthenticated(data.authenticated);
//         onAuthChange(data.authenticated);
//       } catch (error) {
//         console.error("Error checking authentication status:", error);
//       }
//     };

//     checkAuthStatus();
//   }, [onAuthChange]);

//   return (
//     <div>
//       {isAuthenticated ? (
//         <button onClick={handleLogout}>Logout</button>
//       ) : (
//         <Login  />
//       )}
//     </div>
//   );
// }

// export default Auth;

// Auth.js
import React, { useEffect, useState } from "react";
import Login from "./login/Login";

function Auth({ onAuthChange }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    window.location.href = "http://localhost:8000/auth/login";
    onAuthChange(true);
  };

  // Check authentication status on mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(
          "https://hidden-field-eb43.vescu2.workers.dev/auth/isAuthenticated",
          {
            credentials: "include",
          }
        );
        const data = await response.json();
        setIsAuthenticated(data.authenticated);
        onAuthChange(data.authenticated);
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };

    checkAuthStatus();
  }, [onAuthChange]);

  return (
    <div>
      {isAuthenticated ? <div></div> : <Login handleLogin={handleLogin} />}
    </div>
  );
}

export default Auth;
