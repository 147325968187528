import React, { useState } from "react";
import Auth from "./OAuth";
import UserInput from "./UserInput";

function Home() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthChange = (authStatus) => {
    setIsAuthenticated(authStatus);
  };

  return (
    <div>
      {/* Render the Auth component to handle authentication */}
      <Auth onAuthChange={handleAuthChange} />

      {/* Conditionally render UserInput if authenticated */}
      {isAuthenticated && <UserInput />}
    </div>
  );
}

export default Home;
