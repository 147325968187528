import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function Calendar({ places, date }) {
  const [events, setEvents] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);

  // Fetch events from the backend
  const fetchEvents = async () => {
    try {
      const response = await fetch("http://localhost:8000/auth/events", {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        const formattedEvents = data.map((event) => ({
          title: event.summary,
          start: event.start.dateTime || event.start.date,
          end: event.end.dateTime || event.end.date,
        }));
        setEvents(formattedEvents);
        setCalendarEvents(formattedEvents); // Initial calendar display
      } else {
        console.error("Failed to fetch events.");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  // Fetch events on component mount
  useEffect(() => {
    fetchEvents();
  }, []);

  // Add places to calendar and push them to Google Calendar
  const handleAddToGoogleCalendar = async () => {
    const newEvents = [...events];
    let currentDate = new Date();

    for (const place of places) {
      const nextFreeSlot = findNextFreeSlot(newEvents, currentDate);
      if (nextFreeSlot) {
        const newEvent = {
          title: `Visit: ${place.name}`,
          start: nextFreeSlot.start.toISOString(),
          end: nextFreeSlot.end.toISOString(),
          location: place.address,
        };

        // Push to Google Calendar through backend
        try {
          await fetch("http://localhost:8000/auth/add-event", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(newEvent),
          });
          newEvents.push(newEvent);
          setCalendarEvents((prev) => [...prev, newEvent]);
        } catch (error) {
          console.error("Error adding event:", error);
        }
        currentDate = nextFreeSlot.end; // Move to the next available slot
      }
    }
  };

  // Helper function to find the next free one-hour slot
  const findNextFreeSlot = (events, startFrom) => {
    let slotStart = new Date(startFrom);
    slotStart.setMinutes(0, 0, 0); // Start at the beginning of the hour

    while (true) {
      const slotEnd = new Date(slotStart);
      slotEnd.setHours(slotEnd.getHours() + 1);

      const isConflict = events.some(
        (event) =>
          new Date(event.start.dateTime || event.start) < slotEnd &&
          new Date(event.end.dateTime || event.end) > slotStart
      );

      if (!isConflict) {
        return { start: slotStart, end: slotEnd };
      }

      slotStart.setHours(slotStart.getHours() + 1); // Move to the next hour
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center
min-h-screen bg-gray-100 py-8 px-4"
    >
      <div
        className="bg-white p-6 rounded-xl shadow-lg w-full
max-w-4xl text-center"
      >
        <h2 className="text-4xl font-bold text-blue-700 mb-4">
          Your Day at a Glance
        </h2>
        <p className="text-gray-600 mb-6">
          Here’s how your day would look based on your planned visits. Feel free
          to add these events to your Google Calendar to keep everything in
          sync.
        </p>

        <div className="bg-white p-4 rounded-xl shadow-lg mb-8">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridDay" // Show only the day view
            events={calendarEvents}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "", // Remove month and week views
            }}
            height="auto"
            contentHeight="auto"
          />
        </div>

        <button
          onClick={handleAddToGoogleCalendar}
          className="bg-blue-600 text-white px-6 py-3 rounded-lg
font-semibold hover:bg-blue-700 transition-colors duration-200
shadow-lg hover:shadow-xl"
        >
          Add Events to Google Calendar
        </button>
      </div>
    </div>
  );
}

export default Calendar;
