// import { useState } from "react";

// function LLMText({ places }) {
//   const [selectedPlaces, setSelectedPlaces] = useState([]);
//   const [showSelected, setShowSelected] = useState(false);

//   // Toggle selection of a place
//   const togglePlaceSelection = (place) => {
//     setSelectedPlaces((prevSelected) => {
//       const isSelected = prevSelected.find(
//         (selectedPlace) => selectedPlace.name === place.name
//       );
//       if (isSelected) {
//         return prevSelected.filter(
//           (selectedPlace) => selectedPlace.name !== place.name
//         );
//       } else {
//         return [...prevSelected, place];
//       }
//     });
//   };

//   // Handle showing selected places
//   const handleShowSelected = () => {
//     setShowSelected(true);
//   };

//   return (
//     <div>
//       {!showSelected ? (
//         <>
//           <h2>Here are the Popular Places in your area</h2>
//           <h3>Please Select ones that interest you</h3>

//           <div className="grid grid-cols-1 gap-4 mt-4">
//             {places.map((place, index) => (
//               <div
//                 key={index}
//                 onClick={() => togglePlaceSelection(place)}
//                 className={`p-4 border rounded-lg shadow
// cursor-pointer hover:bg-gray-100 ${
//                   selectedPlaces.some(
//                     (selected) => selected.name === place.name
//                   )
//                     ? "bg-blue-200"
//                     : ""
//                 }`}
//               >
//                 <strong>{place.name}</strong>
//                 <br />
//                 {place.address}
//               </div>
//             ))}
//           </div>
//           <button
//             onClick={handleShowSelected}
//             className="py-2 px-2 mt-4 rounded-xl bg-lime-700"
//           >
//             Keep Selected Places
//           </button>
//         </>
//       ) : (
//         <div className="mt-6">
//           <h2>Selected Places:</h2>
//           {selectedPlaces.length > 0 ? (
//             <ul className="list-disc pl-5">
//               {selectedPlaces.map((place, index) => (
//                 <li key={index}>
//                   <strong>{place.name}</strong>: {place.address}
//                 </li>
//               ))}
//             </ul>
//           ) : (
//             <p>No places selected.</p>
//           )}
//         </div>
//       )}
//     </div>
//   );
// }

// export default LLMText;

import { useState } from "react";
import Calendar from "./Calendar";

function LLMText({ places, date }) {
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  

  // Toggle selection of a place
  const togglePlaceSelection = (place) => {
    setSelectedPlaces((prevSelected) => {
      const isSelected = prevSelected.find(
        (selectedPlace) => selectedPlace.name === place.name
      );
      if (isSelected) {
        return prevSelected.filter(
          (selectedPlace) => selectedPlace.name !== place.name
        );
      } else {
        return [...prevSelected, place];
      }
    });
  };

  // Handle showing selected places
  const handleShowSelected = () => {
    setShowSelected(true);
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 py-8 px-4">
      {!showSelected ? (
        <>
          <h2 className="text-3xl font-bold text-blue-800 mb-4">
            Popular Places in Your Area
          </h2>
          <h3 className="text-xl text-gray-600 mb-6">
            Select the ones that interest you
          </h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-4xl">
            {places.map((place, index) => (
              <div
                key={index}
                onClick={() => togglePlaceSelection(place)}
                className={`p-4 border rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-transform transform hover:scale-105 ${
                  selectedPlaces.some(
                    (selected) => selected.name === place.name
                  )
                    ? "bg-blue-100 border-blue-500"
                    : "bg-white"
                }`}
              >
                <strong className="text-lg text-blue-700">{place.name}</strong>
                <p className="text-sm text-gray-600">{place.address}</p>
              </div>
            ))}
          </div>
          <button
            onClick={handleShowSelected}
            className="mt-6 bg-green-600 text-white py-2 px-6 rounded-lg font-semibold shadow-md hover:bg-green-700 transition-colors"
          >
            Keep Selected Places
          </button>
        </>
      ) : (
        <Calendar places={selectedPlaces} date={date} />
      )}
    </div>
  );
}

export default LLMText;
